import React from "react";
import "./App.css";
import Home from "./components/Home/Home";
// import Song from "./components/Song/Song";

function App() {
  return (
    <>
      <Home />
      {/* <Song /> */}
    </>
  );
}

export default App;
